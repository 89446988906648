











































































import {computed, defineComponent, nextTick, onMounted, PropType, ref, watch, watchEffect} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import {
  AiAction,
  AiAgent,
  AiAgentType,
  ChannelActivityAiAgentItem,
  Guid,
} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import channelApi from "@/api/channel-api";
import useSharedUtils from "@/utils/useSharedUtils";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";
import AiRulesSelector from "@/views/ai/_partials/AiRulesSelector.vue";
import AiAgentActivityView from "@/views/ai/_partials/AiAgentActivityView.vue";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import aiApi from "@/api/ai-api";
import AppTooltip from "@/views/_components/AppTooltip.vue";
import Helpers from "@/utils/helpers";
import useModals from "@/utils/useModals";

export default defineComponent({
  components: {
    AppTooltip,
    BSelectFormGroup,
    AiAgentActivityView,
    BTextareaFormGroup,
    AiRulesSelector,
    PageLoadingIndicator
  },
  props: {
    channelId: { type: String as PropType<Guid>, required: true }
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {showDangerConfirmModal} = useModals(context)
    const {closePage} = useSharedUtils()

    const defaultAiAgentInputInstruction = "Enter message"

    const aiAgentIdLocalStorageKey = 'aiAgentId'
    const initialAiAgentId = localStorage.getItem(aiAgentIdLocalStorageKey)

    const isLoading = ref(true)
    const isButtonsDisabled = ref(false)

    const isPageFullscreenMode = computed(() => store.state.ui.isPageFullscreenMode)

    const aiAgents = ref<AiAgent[] | null>(null)
    const aiActions = ref<AiAction[] | null>(null)

    const aiAgentId = ref<Guid | null>(null)
    const aiAgentLanguageId = ref<Guid | null>(null)
    const aiAgentRoleId = ref<Guid | null>(null)
    const aiAgentToneId = ref<Guid | null>(null)

    const aiAgentInputInstruction = ref<string>(defaultAiAgentInputInstruction)

    const isActivityItemsLoading = ref(false)
    const activityItems = ref<ChannelActivityAiAgentItem[] | null>(null)

    const updateAiAgentActivityItems = (cb?: (success: boolean) => void) => {
      if (!helpers.isExists(props.channelId) || !helpers.isExists(aiAgentId.value)) {
        if (helpers.isFunction(cb))
          cb(false)

        return
      }

      isActivityItemsLoading.value = true
      channelApi.getChannelLiveAssistantAiAgentActivityItems(props.channelId, aiAgentId.value, result => {
        if (helpers.isExists(result)) {
          activityItems.value = result.items
        } else {
          activityItems.value = null
        }

        isActivityItemsLoading.value = false

        if (helpers.isFunction(cb))
          cb(true)
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isActivityItemsLoading.value = false

        if (helpers.isFunction(cb))
          cb(false)
      })
    }

    const newRequestText = ref<string | null>(null)

    const sendNewRequestText = () => {
      if (!helpers.isExists(props.channelId) ||
        !helpers.isExists(aiAgentId.value) ||
        !helpers.isNotEmptyOrWhitespace(newRequestText.value)) return

      isButtonsDisabled.value = true
      isActivityItemsLoading.value = true

      channelApi.createChannelLiveAssistantAiAgentResult(props.channelId, aiAgentId.value, aiAgentLanguageId.value,
        aiAgentRoleId.value, aiAgentToneId.value, newRequestText.value, r => {
        if (r.success && r.isAnyChanges) {
          updateAiAgentActivityItems(success => {
            newRequestText.value = null

            isButtonsDisabled.value = false
          })
        } else {
          isButtonsDisabled.value = false
        }
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
      })
    }

    const applyAiAction = (aiActionId: Guid, text: string, cb: (success: boolean) => void) => {
      if (!helpers.isExists(props.channelId) ||
        !helpers.isExists(aiAgentId.value) ||
        !helpers.isExists(aiActionId) ||
        !helpers.isNotEmptyOrWhitespace(text)) return

      isButtonsDisabled.value = true
      isActivityItemsLoading.value = true

      channelApi.createChannelLiveAssistantAiAgentAppliedActionResult(props.channelId, aiAgentId.value, aiActionId,
        aiAgentLanguageId.value, aiAgentRoleId.value, aiAgentToneId.value, text, r => {
          if (r.success && r.isAnyChanges) {
            updateAiAgentActivityItems(success => {
              newRequestText.value = null

              isButtonsDisabled.value = false

              cb(true)
            })
          } else {
            isButtonsDisabled.value = false
            isActivityItemsLoading.value = false

            cb(r.success)
          }
        }, errorMessage => {
          showErrorNotification(errorMessage)

          isButtonsDisabled.value = false
          isActivityItemsLoading.value = false

          cb(false)
        })
    }

    const removeAllItemsWithConfirm = () => {
      if (!Helpers.isExists(aiAgentId.value)) return

      showDangerConfirmModal(async (confirmed) => {
          if (confirmed) {
            isButtonsDisabled.value = true
            isActivityItemsLoading.value = true

            channelApi.removeAllChannelLiveAssistantAiAgentActivityItems(props.channelId, aiAgentId.value!, r => {
              if (r.success) {
                updateAiAgentActivityItems(success => {
                  isButtonsDisabled.value = false
                })
              } else {
                isActivityItemsLoading.value = false
                isButtonsDisabled.value = false
              }
            }, errorMessage => {
              showErrorNotification(errorMessage);

              isButtonsDisabled.value = false
              isActivityItemsLoading.value = false
            })
          }
        }, `Do you really want to clear all items in activity feed?`,
        "Clear Activity Feed")
    }

    watch(aiAgentId, () => {
      const selectedAiAgent = helpers.isNotEmptyArray(aiAgents.value) && helpers.isNotEmpty(aiAgentId.value)
        ? helpers.findFirstInArray(aiAgents.value, x => x.id === aiAgentId.value)
        : null

      aiAgentInputInstruction.value = helpers.isNotEmpty(selectedAiAgent?.inputInstruction)
        ? selectedAiAgent!.inputInstruction
        : defaultAiAgentInputInstruction
    })

    // init
    //
    watchEffect(() => {
      if (helpers.isNotEmpty(aiAgentId.value)) {
        localStorage.setItem(aiAgentIdLocalStorageKey, aiAgentId.value)
      } else {
        localStorage.removeItem(aiAgentIdLocalStorageKey)
      }

      updateAiAgentActivityItems()
    })

    // lookup
    aiApi.aiAgents(AiAgentType.ChannelLiveAssistant, result => {
        aiAgents.value = result.models

        if (helpers.isNotEmpty(initialAiAgentId)) {
          aiAgentId.value = helpers.findFirstInArray(aiAgents.value, x => x.id === initialAiAgentId)?.id ?? null
        } else if (helpers.isNotEmptyArray(aiAgents.value)) {
          aiAgentId.value = aiAgents.value[0].id
        }
      },
      errorMessage => showErrorNotification(errorMessage))
    aiApi.aiActions(result => aiActions.value = result.models,
      errorMessage => showErrorNotification(errorMessage))

    return {
      helpers,
      appUtils,
      AiAgentType,

      isLoading,
      isButtonsDisabled,

      isPageFullscreenMode,

      aiAgents: aiAgents as unknown as AiAgent[],
      aiActions: aiActions as unknown as AiAction[],

      aiAgentId,
      aiAgentLanguageId,
      aiAgentRoleId,
      aiAgentToneId,

      aiAgentInputInstruction,

      isActivityItemsLoading,
      activityItems: activityItems as unknown as ChannelActivityAiAgentItem[],

      newRequestText,
      sendNewRequestText,
      applyAiAction,
      removeAllItemsWithConfirm,
    }
  }
})
