
















































import {computed, defineComponent, nextTick, PropType, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {
  ChannelActivityAiAgentItemTypes,
  ChannelActivityAiAgentItem,
  Guid, AiAction, SelectOption, NativeSelectOption
} from "@/@models";
import dayjs from "dayjs";

export default defineComponent({
  props: {
    item: { type: Object as PropType<ChannelActivityAiAgentItem>, required: true },
    aiActionEnabled: Boolean,
    aiActions: { type: Array as PropType<AiAction[] | null> },
    applyAiAction: { type: Function as PropType<((aiActionId: Guid, text: string, cb: (success: boolean) => void) => void) | null> }
  },
  setup(props, context) {
    const {showSuccessNotification} = useNotifications(context);

    const createdAtFormated = computed(() => dayjs(props.item.createdAt).format('L LT'))

    const sourceTextHtml = computed(() => {
      let contentHtml = ''
      switch (props.item.type) {
        case ChannelActivityAiAgentItemTypes.ResultFromSourceCreated:
          contentHtml = props.item.sourceText ?? ''
          break;
        case ChannelActivityAiAgentItemTypes.ResultFromAppliedAction:
          contentHtml = '<b>' + (props.item.appliedAiActionName ?? '?') + '</b>'
          break;
        default:
          break;
      }

      // todo sanitize html
      return appUtils.convertMarkdownToHtml(contentHtml ?? '')
    })

    const generateParameterChangedString = (parameterName: string, name: string | null, prevName: string | null, nullFallbackName = "(empty)") => {
      const currentName = helpers.isNotEmpty(name) ? "\"" + name + "\"" : nullFallbackName

      if (helpers.isNotEmpty(prevName)) {
        return `${parameterName}: \"${prevName}\" -> ${currentName}`
      } else {
        return `${parameterName}: ${currentName}`
      }
    }

    const resultText = computed(() => {
      let contentHtml = ''
      switch (props.item.type) {
        case ChannelActivityAiAgentItemTypes.ResultFromSourceCreated:
        case ChannelActivityAiAgentItemTypes.ResultFromAppliedAction:
          contentHtml = props.item.text ?? ''
          break;
        case ChannelActivityAiAgentItemTypes.AgentParametersChanged:
          let parameters: string[] = []

          if (props.item.isAiAgentChanged)
            parameters.push(generateParameterChangedString("AI Agent", props.item.aiAgentName, props.item.otherAiAgentName))
          if (props.item.isAiAgentLanguageChanged)
            parameters.push(generateParameterChangedString("Language", props.item.aiAgentLanguageName, props.item.otherAiAgentLanguageName, "Default"))
          if (props.item.isAiAgentRoleChanged)
            parameters.push(generateParameterChangedString("Role", props.item.aiAgentRoleName, props.item.otherAiAgentRoleName, "Default"))
          if (props.item.isAiAgentToneChanged)
            parameters.push(generateParameterChangedString("Tone", props.item.aiAgentToneName, props.item.otherAiAgentToneName, "Default"))

          contentHtml = `<div class="font-weight-bold">Agent Changed</div>${parameters.join('<br/>')}`
          break;
      }

      return contentHtml ?? ''
    })

    // todo sanitize html
    const resultTextHtml = computed(() => appUtils.convertMarkdownToHtml(resultText.value))

    const isCopyAvailable = computed(() =>
      props.item.type != ChannelActivityAiAgentItemTypes.AgentParametersChanged)

    const copyResultToClipboard = () => {
      if (!helpers.isNotEmpty(resultText.value)) return

      helpers.copyToClipboard(resultText.value)

      showSuccessNotification('Text copied to clipboard')
    }

    const selectedAiActionId = ref<Guid | ''>('')
    const isAiActionApplying = ref(false)
    const aiActionOptions = computed(() => {
      if (!helpers.isArray(props.aiActions)) return null

      const options: NativeSelectOption[] = []

      options.push({
        value: '',
        text: "Do Next",
        disabled: true
      })

      for (const aiAction of props.aiActions) {
        options.push({
          value: aiAction.id,
          text: aiAction.name,
        })
      }

      return options
    })

    watch(selectedAiActionId, () => {
      if (!helpers.isNotEmpty(selectedAiActionId.value)) return
      if (!helpers.isFunction(props.applyAiAction) || !helpers.isNotEmpty(resultTextHtml.value)) return

      isAiActionApplying.value = true

      props.applyAiAction(selectedAiActionId.value, resultTextHtml.value, success => {
        selectedAiActionId.value = ''
        isAiActionApplying.value = false
      })
    })

    return {
      helpers,

      createdAtFormated,
      sourceTextHtml,
      resultTextHtml,

      selectedAiActionId,
      isAiActionApplying,

      aiActionOptions: aiActionOptions as unknown as NativeSelectOption[],

      isCopyAvailable,
      copyResultToClipboard
    }
  }
})
