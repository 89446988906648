export type Guid = string
export type DateTime = string

// Events (from iframe to parent)
export enum IframeEventType {
  WindowResize = "window-resize",
}

export interface LandingPageIframeEvent {
  isCidEvent: boolean // always true
  type: IframeEventType
  data: any
}

export interface IframeEventWindowResize extends LandingPageIframeEvent {
  data: {
    scrollWidth: number,
    scrollHeight: number
  }
}

// Commands (from parent to iframe)
export enum IframeCommandType {
  SetIframeSettings = "set-iframe-settings",
}

export enum IframeColorScheme {
  Auto = 0,
  Light = 1,
  Dark = 2,
}

export interface SetIframeSettingsCommand {
  type: IframeCommandType.SetIframeSettings
  returnUrl: string | null
  colorScheme: IframeColorScheme | null
  textColor: string | null
  backgroundColor: string | null,
  injectStyleUrl: string | null,
}

export enum ChannelLandingPageCharitySizeType {
  Unknown = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
  Super = 4,
}

export enum CharityAddressType {
  Physical = 0,
  Mailing = 1,
}

export enum CurrencyNumericCode {
  Undefined = 0,

  GBP = 826,
  USD = 840,
  EUR = 978,
}

export interface ChannelLandingPageCause {
  id: Guid
  name: string
}

export enum ChannelLandingPageTelegramChatType {
  Private = 0,
  Group = 1,
  Channel = 2,
  Supergroup = 3,
}

export enum ChannelLandingPageTelegramChatMessageMediaType {
  Unknown = 0,
  Photo = 1,
  Video = 2,
  Audio = 3,
  Document = 4,
  VideoPlayer = 5,
}

export enum ChannelLandingPageFileType {
  Raw = 0,
  Image = 1,
  Video = 2,
  Audio = 3,
}

export interface ChannelLandingPageFile {
  id: string
  type: ChannelLandingPageFileType
  fileMimeType: string
  fileSizeBytes: number
  width: number | null
  height: number | null
  duration: number | null

  absoluteUrl: string
}

export interface ChannelLandingPageTelegramChatMessageMedia {
  type: ChannelLandingPageTelegramChatMessageMediaType
  mimeType: string
  sizeInBytes: number
  url: string
}

export interface ChannelLandingPageTelegramChatMessage {
  createdAt: DateTime
  url: string
  mediaSet: ChannelLandingPageTelegramChatMessageMedia[]
  message: string | null
}

export interface ChannelLandingPageTelegramChat {
  type: ChannelLandingPageTelegramChatType
  title: string
  description: string | null
  isPublic: boolean
  username: string | null
  url: string
  // reverse order
  messages: ChannelLandingPageTelegramChatMessage[]
  hasMoreMessages: boolean
}

export interface ChannelLandingPageTelegramGalleryMedia {
  type: ChannelLandingPageTelegramChatMessageMediaType
  mimeType: string
  sizeInBytes: number
  thumbnailUrl: string
  url: string

  messageUrl: string
}

export interface ChannelLandingPageCharityAddress {
  type: CharityAddressType
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  zip: string | null
  state: string | null
  country: string | null
}

export interface ChannelLandingPageCharity {
  id: Guid
  ein: string | null
  logoFileUrl: string | null
  name: string
  sizeType: ChannelLandingPageCharitySizeType
  locationStateName: string | null
  locationCountryName: string | null
  descriptionShort: string | null
  descriptionLong: string | null
  causes: ChannelLandingPageCause[]
  addresses: ChannelLandingPageCharityAddress[]
  websiteUrl: string | null
  phone: string | null
}

export interface ChannelLandingPageSupporter {
  supportedAt: DateTime
  name: string | null
  currencyCode: CurrencyNumericCode
  amount: number
}

export interface ChannelLandingPage {
  channelPublicId: string
  channelName: string
  channelProfilePicture: ChannelLandingPageFile | null
  channelCauses: ChannelLandingPageCause[]
  channelStory: string | null
  channelStoryMediaSet: ChannelLandingPageFile[]
  channelMission: string
  channelMissionVideo: ChannelLandingPageFile | null
  telegram: ChannelLandingPageTelegramChat | null
  telegramGalleryMediaSet: ChannelLandingPageTelegramGalleryMedia[] | null
  charity: ChannelLandingPageCharity | null
  supporters: ChannelLandingPageSupporter | null
  isChannelSupportPageEnabled: boolean
  channelSupportPageIframeUrl: string | null
}