








































import {computed, defineComponent, nextTick, PropType, Ref, ref, watch} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import {Cause, ChannelInfo, Guid, TelegramBotRoute,} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import channelApi from "@/api/channel-api";
import causeApi from "@/api/cause-api";
import useModals from "@/utils/useModals";
import useSharedUtils from "@/utils/useSharedUtils";
import CauseListGroupItemView from "@/views/channel/_partials/CauseListGroupItemView.vue";
import telegramApi from "@/api/telegram-api";
import debounce from "debounce";

export default defineComponent({
  components: {
    PageLoadingIndicator, CauseListGroupItemView,
  },
  props: {
    channelInfo: { type: Object as PropType<ChannelInfo>, required: true },
    showTitle: Boolean,
    loading: Boolean,
    disabled: Boolean
  },
  emits: ['update-channel-info'],
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {showSuccessConfirmModal} = useModals(context)

    const telegramMessageLinkId = router.currentRoute.query.telegramMessageLinkId as string | null

    const isLoadingLocal = ref(true)
    const isLoadingFinal = computed(() => props.loading || isLoadingLocal.value)
    const isButtonsDisabledLocal = ref(false)
    const isButtonsDisabledFinal = computed(() => props.disabled || isButtonsDisabledLocal.value)

    const rootCauses = ref<Cause[] | null>(null)

    const selectedCauseNames = computed(() => {
      if (!helpers.isExists(props.channelInfo)) return

      const names: string[] = []
      for (const causeId of props.channelInfo.causeIds) {
        const cause = findCause(causeId)

        if (helpers.isExists(cause)) {
          names.push(cause.name)
        }
      }

      return names.sort()
    })

    const findCause = (causeId: Guid) => {
      if (!helpers.isNotEmptyArray(rootCauses.value)) return null

      return findCauseInCauses(causeId, rootCauses.value)
    }
    const findCauseInCauses = (causeId: Guid, causes: Cause[]): Cause | null => {
      for (const cause of causes) {
        if (cause.id === causeId) return cause

        if (helpers.isNotEmptyArray(cause.children)) {
          const childCause = findCauseInCauses(causeId, cause.children)

          if (helpers.isExists(childCause)) return childCause
        }
      }

      return null
    }

    const isCauseSelected = (causeId: Guid) => {
      if (!helpers.isExists(props.channelInfo)) return false

      return helpers.anyInArray(props.channelInfo.causeIds, x => x === causeId)
    }

    const tryUpdateTelegramBotViewRoute = () => {
      if (!helpers.isNotEmpty(telegramMessageLinkId) || !helpers.isExists(props.channelInfo)) return

      telegramApi.openTelegramBotRoute(telegramMessageLinkId, TelegramBotRoute.CauseAndEarn_Channel_Edit,
        props.channelInfo.id, r => {

      }, errorMessage => {
        console.error(errorMessage)
      })
    }

    let isCausesUpdating = false
    const updateCausesWithTimer = debounce(() => {
      if (isCausesUpdating) {
        // try again after timeout
        updateCausesWithTimer()
        return
      }

      if (!helpers.isExists(props.channelInfo)) return

      isCausesUpdating = true
      channelApi.updateCauses(props.channelInfo.id, props.channelInfo.causeIds, r => {
        if (r.success && r.isAnyChanges) {
          context.emit('update-channel-info', () => {
            // update telegram bot view
            tryUpdateTelegramBotViewRoute()
          })
        }

        isCausesUpdating = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isCausesUpdating = false
      })
    }, 500);

    const changeCauseSelectionWithConfirm = (causeId: Guid, isSelected: Ref<boolean>) => {
      if (!helpers.isExists(props.channelInfo)) return

      const cause = findCause(causeId)

      if (!helpers.isExists(cause)) return

      if (isSelected.value === isCauseSelected(cause.id)) return

      const action = () => {
        if (!helpers.isExists(props.channelInfo)) return

        if (isSelected.value) {
          props.channelInfo.causeIds.push(cause.id)
        } else {
          helpers.removeInArray(props.channelInfo.causeIds, x => x === cause.id)
        }

        updateCausesWithTimer()
      }

      if (isSelected.value) {
        action()
      } else {
        showSuccessConfirmModal(confirmed => {
          if (confirmed) {
            action()
          } else {
            isSelected.value = !isSelected.value
          }
        }, `Do you really want to disable "${cause.name}"?`, "Disable Cause")
      }
    }

    // init
    //
    // lookup
    causeApi.list(result => {
        rootCauses.value = result.causes

        isLoadingLocal.value = false
      },
      errorMessage => showErrorNotification(errorMessage), null, true)

    return {
      helpers,
      appUtils,

      isLoadingFinal,
      isButtonsDisabledFinal,

      rootCauses: rootCauses as unknown as Cause[],

      selectedCauseNames,

      isCauseSelected,
      changeCauseSelectionWithConfirm,
    }
  }
})
