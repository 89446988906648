






































































































































import {computed, defineComponent, onMounted, PropType, ref, watch, WritableComputedRef} from "@vue/composition-api";
import Helpers from "@/utils/helpers";
import useNotifications from "@/components/useNotifications";
import helpers from "@/utils/helpers";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";

// (telegram) https://sendpulse.com/knowledge-base/chatbot/telegram/format-text
enum TextAction {
  bold,
  italic,
  underline,
  strikethrough,
  monoCode,
  monoPre,
  link,

  // telegram only
  tgSpoiler,
}

interface ExtendedSelection extends Selection {
  modify(s: string, t: string, u: string): void;
}

export default defineComponent({
  components: {
    BTextareaFormGroup
  },
  props: {
    inputId: String,
    value: String,
    disabled: Boolean,
    type: { type: String as PropType<"default" | "telegram">, default: "default" }
  },
  emits: ['input'],
  setup(props, context) {
    const { showSuccessNotification, showErrorNotification } = useNotifications(context);

    const isPreviewVisible = ref(false)

    const isTelegramType = computed(() => props.type === "telegram")

    const innerValue: WritableComputedRef<string | null> = computed({
      get() {
        return props.value as string | null;
      },
      set(val) {
        context.emit('input', val);
      }
    });

    const componentRef = `SimpleRichTextEditor_${props.inputId}`;

    const element = ref<HTMLElement | null>()

    const postFixContent = (content: string) => {
      return content.replaceAll(/<\/div>/g, "")
        .replaceAll(/<div>/g, '')
        .replaceAll(/<br>/g, '')
    }

    const currentContent = () => {
      return element.value!.innerHTML
    }

    const updateContent = (newContent: string) => {
      element.value!.innerHTML = postFixContent(newContent)
    }

    const onUpdate = (event: KeyboardEvent) => {
      innerValue.value = currentContent()
    }
    const onKeydown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault()

        insertTextAtCursor('\n')
        innerValue.value = currentContent()
      }
    }
    const insertTextAtCursor = (text: string) => {
      let selection = window.getSelection() as ExtendedSelection;

      if (!helpers.isExists(selection)) return

      let range = selection.getRangeAt(0);
      range.deleteContents();

      let node = document.createTextNode(text);
      range.insertNode(node);

      for (let position = 0; position != text.length; position++) {
        selection.modify("move", "right", "character");
      }
    }

    const wrapOrUnwrapSelectionWithElement = (elementTag: string,
                                              getAttributes: (() => { [name: string]: string } | boolean) | null = null) => {
      let selection = window.getSelection() as ExtendedSelection;

      if (!helpers.isExists(selection)) return

      if (selection.rangeCount <= 0) return
      if (selection.isCollapsed) return

      let range = selection.getRangeAt(0).cloneRange()

      const selectionElement = range.commonAncestorContainer
      const selectionParentElement = selectionElement.parentElement

      if (helpers.isExists(selectionParentElement) && selectionParentElement.nodeName.toLowerCase() === elementTag) {
        const parentOfParentNode = selectionParentElement.parentNode;

        if (!helpers.isExists(parentOfParentNode)) return

        while (selectionParentElement.firstChild) {
          parentOfParentNode.insertBefore(selectionParentElement.firstChild, selectionParentElement)
        }

        parentOfParentNode.removeChild(range.commonAncestorContainer)
      } else {
        const element = document.createElement(elementTag);

        if (helpers.isFunction(getAttributes)) {
          const attributes: any = getAttributes()

          if (helpers.isBoolean(attributes) && !attributes) return

          for (const attributesKey in attributes) {
            element.setAttribute(attributesKey, attributes[attributesKey]);
          }
        }

        range.surroundContents(element);
        range.collapse()

        selection.removeAllRanges();
        selection.addRange(range);
      }
    }

    const makeTextAction = (action: TextAction) => {
      switch (action) {
        case TextAction.bold:
          document.execCommand("bold", false)
          //wrapOrUnwrapSelectionWithElement('b')
          break;
        case TextAction.italic:
          document.execCommand("italic", false)
          //wrapOrUnwrapSelectionWithElement('i')
          break;
        case TextAction.underline:
          document.execCommand("underline", false)
          //wrapOrUnwrapSelectionWithElement('u')
          break;
        case TextAction.strikethrough:
          document.execCommand("strikeThrough", false)
          //wrapOrUnwrapSelectionWithElement('s')
          break;
        case TextAction.monoCode:
          wrapOrUnwrapSelectionWithElement('code')
          break;
        case TextAction.monoPre:
          wrapOrUnwrapSelectionWithElement('pre')
          break;
        case TextAction.link:
          wrapOrUnwrapSelectionWithElement('a', () => {
            const linkUrl = prompt("Enter link URL")

            if (!helpers.isNotEmpty(linkUrl)) return false

            return {
              'href': linkUrl
            }
          })
          break;
        // telegram only
        case TextAction.tgSpoiler:
          if (!isTelegramType.value) {
            console.error(`tgSpoiler action not supported for type - action: ${action}`)
            return
          }

          wrapOrUnwrapSelectionWithElement('tg-spoiler')
          break;
      }

      innerValue.value = currentContent()
    }

    const onPaste = (event: ClipboardEvent) => {
      event.preventDefault();
      let text = event.clipboardData?.getData('text/plain');

      if (!helpers.isNotEmpty(text)) return

      window.document.execCommand('insertText', false, text);
    }

    watch(() => props.value, (val) => {
      if (val !== postFixContent(currentContent())) {
        updateContent(val ?? '')
      }
    })

    onMounted(() =>{
      element.value = context.refs[componentRef] as HTMLElement

      updateContent(props.value ?? '')
    })

    return {
      helpers: Helpers,
      TextAction,

      componentRef,

      isPreviewVisible,
      isTelegramType,
      innerValue,

      makeTextAction,
      onUpdate,
      onKeydown,
      onPaste,
    }
  }
})
