



















































import {computed, defineComponent, nextTick, PropType, ref, watch, WritableComputedRef} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import useNotifications from "@/components/useNotifications";
import {AiLanguage, AiTone, AiRole, Guid} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useSharedUtils from "@/utils/useSharedUtils";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import aiApi from "@/api/ai-api";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";

export default defineComponent({
  components: {BTextareaFormGroup, BSelectFormGroup, PageLoadingIndicator},
  props: {
    aiLanguageId: String as PropType<Guid | null>,
    aiRoleId: String as PropType<Guid | null>,
    aiToneId: String as PropType<Guid | null>,
    disabled: Boolean
  },
  setup(props, context) {
    const store = useStore()
    const {showSuccessNotification, showErrorNotification} = useNotifications(context)

    const isLoading = ref(true)

    const isButtonsDisabledLocal = ref(false)
    const isButtonsDisabledFinal = computed(() => props.disabled || isButtonsDisabledLocal.value)

    const aiLanguageIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiLanguageId as Guid | null;
      },
      set(val) {
        context.emit('update:aiLanguageId', val);
      }
    })

    const aiRoleIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiRoleId as Guid | null;
      },
      set(val) {
        context.emit('update:aiRoleId', val);
      }
    })

    const aiToneIdValue: WritableComputedRef<Guid | null> = computed({
      get() {
        return props.aiToneId as Guid | null;
      },
      set(val) {
        context.emit('update:aiToneId', val);
      }
    })

    const aiLanguages = ref<AiLanguage[] | null>(null)
    const aiRoles = ref<AiRole[] | null>(null)
    const aiTones = ref<AiTone[] | null>(null)

    // init
    //
    // lookup
    aiApi.aiLanguages(result => aiLanguages.value = result.models,
      errorMessage => showErrorNotification(errorMessage))
    aiApi.aiRoles(result => aiRoles.value = result.models,
      errorMessage => showErrorNotification(errorMessage))
    aiApi.aiTones(result => aiTones.value = result.models,
      errorMessage => showErrorNotification(errorMessage))

    isLoading.value = false

    return {
      helpers,

      isLoading,
      isButtonsDisabledFinal,

      aiLanguageIdValue,
      aiRoleIdValue,
      aiToneIdValue,

      aiLanguages,
      aiRoles,
      aiTones,
    }
  }
})
