import {CharityInfo} from "@/@models/charity";

export type Guid = string
export const EmptyGuid = "00000000-0000-0000-0000-000000000000"
export type DateTime = string

export type Implements<T, U extends T> = {}

export enum ValidationItemType {
  Undefined = 0,
  Error = 1,
  Warning = 2,
}

export enum ApiResponseDefaultErrorCodes {
  UnknownError = "unknown_error",
  ServerUnavailable = "server_unavailable",
  InternalServerError = "internal_server_error",
  InternalValidationError = "internal_validation_error",
  Unauthorized = "unauthorized",
  UnsupportedApiVersion = "unsupported_api_version",
  ApiVersionUnspecified = "api_version_unspecified",
  InvalidApiVersion = "invalid_api_version",
  AmbiguousApiVersion = "ambiguous_api_version"
}

export interface ApiResponseValidationItem {
  type: ValidationItemType
  code: ApiResponseDefaultErrorCodes | string
  details: string
  parameters?: { [key: string]: string } | null
}

export interface ApiResponseValidationResult {
  items: ApiResponseValidationItem[]
}

export interface ApiResponse<T = any> {
  success: boolean
  isAnyChanges: boolean
  timestamp: DateTime
  validationResult?: ApiResponseValidationResult
  result?: T | null
}

export interface SelectOption {
  id: number | string
  label: string

  group?: string | null
  level?: number | null
}

export interface NativeSelectOption {
  value: any
  text: string
  disabled?: boolean
}

export interface EnumSelectOption {
  id: number | string
  label: string
  altLabel?: string
  subtitle?: string
}

export enum FileType {
  Raw = 0,
  Image = 1,
  Video = 2,
  Audio = 3,
}

export enum FileMetatype {
  Undefined = 0,
  CountryFlagImage = 1,
  KnowledgeBaseArticleContent = 2,
  LocalizationStringText = 3,

  UserProfileImage = 100,

  ChannelProfileImage = 200,
  ChannelMissionVideo = 201,
}

export interface FileMetadataItemJson {
  key: string
  value: string
}

export interface FileFullInfo {
  id: string
  type: FileType
  createdAt: DateTime
  metatype: FileMetatype
  fileName: string
  fileExtension: string
  fileMimeType: string
  fileSizeBytes: number
  width: number | null
  height: number | null
  duration: number | null
  metadata: FileMetadataItemJson[] | null
  absoluteUrl: string
}

export interface TableResult<TItem> {
  totalCount: number;
  items: TItem[]
}

export interface ListWithPagination<TResult> {
  page: number
  perPageCount: number
  totalCount: number
  results: TResult[]
}

declare global {
  interface Window {
    Telegram: Telegram;
  }
}