






import {computed, defineComponent, nextTick, watch} from "@vue/composition-api";
import useRouter from "@/router";
import {UiMutationTypes} from "@/store/modules/ui/types";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import telegramApi from "@/api/telegram-api";
import {isCurrentTelegramUserDev} from "@/utils/devUtils";
import appUtils from "@/utils/app-utils";

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    const redirectStartParameters = router.currentRoute.query.startParameters

    const processStartParam = (startParam: string): boolean => {
      if (startParam.startsWith("cb-")) {
        const callbackAction = startParam.split("cb-")[1]

        if (!helpers.isNotEmpty(callbackAction)) return false;

        router.push({
          name: 'callback',
          params: {
            action: callbackAction
          }
        })

        return true;
      } else if (startParam === "premium") {
        router.push({
          name: 'premium'
        })

        return true;
      } else if (startParam.startsWith("pl-")) {
        const paymentLinkPublicId = startParam.split("pl-")[1]

        if (!helpers.isNotEmpty(paymentLinkPublicId)) return false;

        router.push({
          name: 'payment-link',
          params: {
            paymentLinkPublicId: paymentLinkPublicId
          }
        })

        return true;
      } else if (startParam.startsWith("cs-")) {
        const parts = startParam.split("cs-")[1].split("-")
        const channelPublicId = parts[0]
        const isSubscriptionOnly = parts[1] === "sub"

        if (!helpers.isNotEmpty(channelPublicId)) return false;

        store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

        router.push({
          name: 'channel-support',
          params: {
            channelPublicId: channelPublicId
          },
          query: {
            subscriptionOnly: isSubscriptionOnly ? "1" : undefined
          }
        })

        return true;
      } else if (startParam.startsWith("ca-causes-and-charity-")) {
        const parts = startParam.split("ca-causes-and-charity-")[1].split("-")
        const channelPublicId = parts[0]
        const type = parts[1]
        const telegramMessageLinkId = parts[2]

        if (!helpers.isNotEmpty(channelPublicId)) return false;

        store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

        router.push({
          name: 'channel-admin-causes-and-charity',
          params: {
            channelPublicId: channelPublicId
          },
          query: {
            type: type,
            telegramMessageLinkId: telegramMessageLinkId
          }
        })

        return true;
      } else if (startParam.startsWith("ca-ai-agents-")) {
        let parts = startParam.split("ca-ai-agents-")

        if (parts.length > 1) {
          parts = parts[1].split("_")
        }

        const type = parts[0] ?? null
        const channelPublicId = parts[1] ?? null
        const contentSourceId = parts[2] ?? null

        store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

        router.push({
          name: 'channel-admin-ai-agents',
          query: {
            type: type,
            channelPublicId: channelPublicId,
            contentSourceId: contentSourceId
          }
        })

        return true;
      } else if (startParam.startsWith("mp-")) {
        let channelPublicId: string | null = startParam.split("mp-")[1]

        if (!helpers.isNotEmpty(channelPublicId)) return false;

        if (channelPublicId === "account") {
          router.push({
            name: 'payment-management-portal'
          })
        } else {
          router.push({
            name: 'payment-management-portal',
            params: {
              channelPublicId: channelPublicId
            }
          })
        }

        return true;
      } else if (startParam.startsWith("kba-")) {
        const articlePublicId = startParam.split("kba-")[1]

        if (!helpers.isNotEmpty(articlePublicId)) return false;

        router.push({
          name: 'knowledge-base-article',
          params: {
            publicId: articlePublicId
          }
        })

        return true;
      } else if (startParam === "payment-account-link") {
        router.push({
          name: 'payment-account-link'
        })

        return true;
      } else if (startParam.startsWith("admin-")) {
        const adminAction = startParam.split("admin-")[1]

        if (!helpers.isNotEmpty(adminAction)) return false;

        if (adminAction.startsWith("localization--")) {
          const localizationKeyHint = adminAction.split("localization--")[1]

          router.push({
            name: 'admin-localization-editor',
            query: {
              keyHint: localizationKeyHint
            }
          })

          return true;
        }

        return false;
      }

      store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

      return false;
    }

    // init
    //
    store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, true);

    const telegramApp = window.Telegram.WebApp
    const telegramInitData = telegramApp.initData

    if (helpers.isNotEmpty(telegramInitData)) {
      telegramApi.validateInitData(telegramInitData, r => {
        if (r.success) {
          if (helpers.isNotEmpty(redirectStartParameters)) {
            if (processStartParam(redirectStartParameters)) {
              store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);
              return;
            }
          } else if (helpers.isNotEmpty(telegramApp.initDataUnsafe.start_param)) {
            if (processStartParam(telegramApp.initDataUnsafe.start_param!)) {
              store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);
              return;
            }
          }
        }

        if (isCurrentTelegramUserDev())
          console.log("=========== CLOSE TELEGRAM APP ============")
        else
          telegramApp.close()
      }, errorMessage => {
        console.error(errorMessage)

        if (isCurrentTelegramUserDev())
          console.log("=========== CLOSE TELEGRAM APP ============")
        else
          telegramApp.close()
      })
    } else if (helpers.isNotEmpty(redirectStartParameters)) {
      if (processStartParam(redirectStartParameters)) {
        store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);
        return;
      }
    } else {
      // redirect to root
      store.commit(UiMutationTypes.UPDATE_GLOBAL_LOADING, false);

      if (isCurrentTelegramUserDev())
        console.log("=========== REDIRECT TO ROOT ============")
      else
        router.push("/")
    }

    return {
    }
  }
})
