import { render, staticRenderFns } from "./ChannelAiAgents.vue?vue&type=template&id=4d9b4cd3&scoped=true&"
import script from "./ChannelAiAgents.vue?vue&type=script&lang=ts&"
export * from "./ChannelAiAgents.vue?vue&type=script&lang=ts&"
import style0 from "./ChannelAiAgents.vue?vue&type=style&index=0&id=4d9b4cd3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.6.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d9b4cd3",
  null
  
)

export default component.exports