import {
  Guid,
  AiAgentAdminEdit,
  AiAgentAdminTable,
  AiLanguageAdminTable,
  AiLanguageAdminEdit,
  AiRoleAdminTable,
  AiRoleAdminEdit,
  AiToneAdminTable,
  AiToneAdminEdit,
  AiActionAdminTable,
  AiActionAdminEdit
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getAiAgentsForTable(successCb: ApiSuccessCallback<AiAgentAdminTable[]>,
                      errorCb: ApiErrorCallback<AiAgentAdminTable[]>) {
    return this._defaultPostResponse('/ai/ai-agent-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiAgentForEdit(id: Guid,
                    successCb: ApiSuccessCallback<AiAgentAdminEdit>,
                    errorCb: ApiErrorCallback<AiAgentAdminEdit>) {
    return this._defaultPostResponse('/ai/get-edit-ai-agent', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAgent(model: AiAgentAdminEdit,
                successCb: ApiSuccessCallback<Guid | null>,
                errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai/edit-ai-agent', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAgent(id: Guid,
                successCb: ApiEmptySuccessCallback,
                errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai/delete-ai-agent', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiLanguagesForTable(successCb: ApiSuccessCallback<AiLanguageAdminTable[]>,
                         errorCb: ApiErrorCallback<AiLanguageAdminTable[]>) {
    return this._defaultPostResponse('/ai/ai-language-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiLanguageForEdit(id: Guid,
                       successCb: ApiSuccessCallback<AiLanguageAdminEdit>,
                       errorCb: ApiErrorCallback<AiLanguageAdminEdit>) {
    return this._defaultPostResponse('/ai/get-edit-ai-language', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiLanguage(model: AiLanguageAdminEdit,
                   successCb: ApiSuccessCallback<Guid | null>,
                   errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai/edit-ai-language', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiLanguage(id: Guid,
                   successCb: ApiEmptySuccessCallback,
                   errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai/delete-ai-language', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiRolesForTable(successCb: ApiSuccessCallback<AiRoleAdminTable[]>,
                     errorCb: ApiErrorCallback<AiRoleAdminTable[]>) {
    return this._defaultPostResponse('/ai/ai-role-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiRoleForEdit(id: Guid,
                   successCb: ApiSuccessCallback<AiRoleAdminEdit>,
                   errorCb: ApiErrorCallback<AiRoleAdminEdit>) {
    return this._defaultPostResponse('/ai/get-edit-ai-role', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiRole(model: AiRoleAdminEdit,
               successCb: ApiSuccessCallback<Guid | null>,
               errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai/edit-ai-role', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiRole(id: Guid,
               successCb: ApiEmptySuccessCallback,
               errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai/delete-ai-role', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiTonesForTable(successCb: ApiSuccessCallback<AiToneAdminTable[]>,
                     errorCb: ApiErrorCallback<AiToneAdminTable[]>) {
    return this._defaultPostResponse('/ai/ai-tone-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiToneForEdit(id: Guid,
                   successCb: ApiSuccessCallback<AiToneAdminEdit>,
                   errorCb: ApiErrorCallback<AiToneAdminEdit>) {
    return this._defaultPostResponse('/ai/get-edit-ai-tone', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiTone(model: AiToneAdminEdit,
               successCb: ApiSuccessCallback<Guid | null>,
               errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai/edit-ai-tone', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiTone(id: Guid,
               successCb: ApiEmptySuccessCallback,
               errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai/delete-ai-tone', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiActionsForTable(successCb: ApiSuccessCallback<AiActionAdminTable[]>,
                       errorCb: ApiErrorCallback<AiActionAdminTable[]>) {
    return this._defaultPostResponse('/ai/ai-action-table', {
      telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  getAiActionForEdit(id: Guid,
                     successCb: ApiSuccessCallback<AiActionAdminEdit>,
                     errorCb: ApiErrorCallback<AiActionAdminEdit>) {
    return this._defaultPostResponse('/ai/get-edit-ai-action', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  updateAiAction(model: AiActionAdminEdit,
                 successCb: ApiSuccessCallback<Guid | null>,
                 errorCb: ApiErrorCallback<Guid | null>) {
    return this._defaultPostResponse('/ai/edit-ai-action', {
      model, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }

  deleteAiAction(id: Guid,
                 successCb: ApiEmptySuccessCallback,
                 errorCb: ApiEmptyErrorCallback) {
    return this._defaultEmptyPostResponse('/ai/delete-ai-action', {
      id, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}
