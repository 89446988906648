





















































import {computed, defineComponent, nextTick, onMounted, ref, watch, watchEffect} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import {
  Channel, Guid,
} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import channelApi from "@/api/channel-api";
import useSharedUtils from "@/utils/useSharedUtils";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import ChannelLiveAssistantAiAgentView from "@/views/ai/ChannelLiveAssistantAiAgentView.vue";
import ChannelAutomatedAssistantAiAgentView from "@/views/ai/ChannelAutomatedAssistantAiAgentView.vue";

enum AiAgentTab {
  LiveAssistant = 0,
  AutomatedAssistant = 1
}

export default defineComponent({
  components: {
    BSelectFormGroup, PageLoadingIndicator, ChannelLiveAssistantAiAgentView, ChannelAutomatedAssistantAiAgentView
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {closePage} = useSharedUtils()

    const type = router.currentRoute.query.type
    const channelPublicId = router.currentRoute.query.channelPublicId

    const channelIdLocalStorageKey = 'channelId'
    const initialChannelId = localStorage.getItem(channelIdLocalStorageKey)

    const isLoading = ref(true)
    const isButtonsDisabled = ref(false)

    const activeTab = ref<AiAgentTab | null>(null)

    const channels = ref<Channel[] | null>(null)
    const selectedChannelId = ref<Guid | null>(null)

    const isPageFullscreenMode = computed(() => store.state.ui.isPageFullscreenMode)

    // init
    //
    watch(selectedChannelId, () => {
      if (helpers.isNotEmpty(selectedChannelId.value)) {
        localStorage.setItem(channelIdLocalStorageKey, selectedChannelId.value)
      } else {
        localStorage.removeItem(channelIdLocalStorageKey)
      }
    })

    switch (type) {
      case "auto":
        activeTab.value = AiAgentTab.AutomatedAssistant
        break
      case "live":
      default:
        activeTab.value = AiAgentTab.LiveAssistant
        break
    }

    isButtonsDisabled.value = true

    channelApi.channels(result => {
      if (helpers.isExists(result)) {
        channels.value = result.models

        if (helpers.isNotEmptyArray(channels.value)) {
          if (helpers.isNotEmpty(channelPublicId)) {
            selectedChannelId.value = helpers.findFirstInArray(channels.value, x => x.publicId === channelPublicId)?.id ?? null
          } else if (helpers.isNotEmpty(initialChannelId)) {
            selectedChannelId.value = helpers.findFirstInArray(channels.value, x => x.id === initialChannelId)?.id ?? null
          }

          if (!helpers.isNotEmpty(selectedChannelId.value)) {
            selectedChannelId.value = channels.value[0].id
          }
        }

        isLoading.value = false
        isButtonsDisabled.value = false
      } else {
        closePage()
      }
    }, errorMessage => {
      showErrorNotification(errorMessage)

      closePage()
    })

    return {
      helpers,
      appUtils,

      isLoading,
      isButtonsDisabled,

      activeTab,

      channels: channels as unknown as Channel[],
      selectedChannelId,

      isPageFullscreenMode
    }
  }
})
