import {DateTime, FileFullInfo, Guid} from "@/@models/shared";
import {CharityAddressType} from "@/@models/charity";

export interface Channel {
  id: Guid
  publicId: string
  name: string
}
export interface Channels {
  models: Channel[]
}

export interface ChannelInfo {
  id: Guid
  causeIds: Guid[]
  charityId: Guid | null
}

export enum ChannelLandingPageCharitySizeType {
  Unknown = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
  Super = 4,
}

export interface ChannelLandingPageCause {
  id: Guid
  name: string
}

export interface ChannelLandingPageCharityAddress {
  type: CharityAddressType
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  zip: string | null
  state: string | null
  country: string | null
}

export interface ChannelLandingPageCharity {
  id: Guid
  ein: string | null
  logoFileUrl: string | null
  name: string
  sizeType: ChannelLandingPageCharitySizeType
  locationStateName: string | null
  locationCountryName: string | null
  descriptionShort: string | null
  descriptionLong: string | null
  causes: ChannelLandingPageCause[]
  addresses: ChannelLandingPageCharityAddress[]
  websiteUrl: string | null
  phone: string | null
}

export interface ChannelAutomatedAssistantAiAgentInfo {
  channelId: Guid
  isEnabled: boolean
  aiAgentId: Guid | null
  aiAgentLanguageId: Guid | null
  aiAgentRoleId: Guid | null
  aiAgentToneId: Guid | null
}

export enum ChannelActivityAiAgentItemTypes {
  ResultFromSourceCreated = "result_from_source_created",
  ResultFromAppliedAction = "result_from_applied_action",
  AgentParametersChanged = "agent_parameters_changed"
}

export interface ChannelActivityAiAgentItem {
  id: Guid
  createdAt: DateTime
  type: ChannelActivityAiAgentItemTypes

  isAiAgentChanged: boolean
  aiAgentName: string | null
  otherAiAgentName: string | null

  isAiAgentLanguageChanged: boolean
  aiAgentLanguageName: string | null
  otherAiAgentLanguageName: string | null

  isAiAgentRoleChanged: boolean
  aiAgentRoleName: string | null
  otherAiAgentRoleName: string | null

  isAiAgentToneChanged: boolean
  aiAgentToneName: string | null
  otherAiAgentToneName: string | null

  isAiActionApplied: boolean
  appliedAiActionName: string | null

  sourceText: string | null
  text: string | null
}

export interface ChannelActivityAiAgentItems {
  items: ChannelActivityAiAgentItem[]
}

export interface ChannelLandingPage {
  channelPublicId: string
  channelName: string
  channelUrl: string
  channelCauses: ChannelLandingPageCause[]
  channelVideoFile: FileFullInfo | null
  channelMission: string
  charity: ChannelLandingPageCharity | null
  isChannelSupportPageEnabled: boolean
  channelSupportPageIframeUrl: string | null
}