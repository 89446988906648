









































































import {computed, defineComponent, nextTick, onMounted, PropType, ref, watch, watchEffect} from "@vue/composition-api";
import useRouter from "@/router";
import useStore from "@/store";
import helpers from "@/utils/helpers";
import appUtils from "@/utils/app-utils";
import {
  AiAgent,
  AiAgentType,
  ChannelActivityAiAgentItem,
  ChannelAutomatedAssistantAiAgentInfo, ContentSource, Guid,
} from "@/@models";
import PageLoadingIndicator from "@/views/_components/PageLoadingIndicator.vue";
import useNotifications from "@/components/useNotifications";
import channelApi from "@/api/channel-api";
import useSharedUtils from "@/utils/useSharedUtils";
import BTextareaFormGroup from "@/views/_controls/BTextareaFormGroup.vue";
import AiRulesSelector from "@/views/ai/_partials/AiRulesSelector.vue";
import AiAgentActivityView from "@/views/ai/_partials/AiAgentActivityView.vue";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import aiApi from "@/api/ai-api";
import contentSourceApi from "@/api/content-source-api";
import Helpers from "@/utils/helpers";
import aiPortalApi from "@/api/portal/ai-portal-api";
import useModals from "@/utils/useModals";

export default defineComponent({
  components: {
    BSelectFormGroup,
    AiAgentActivityView,
    BTextareaFormGroup,
    AiRulesSelector,
    PageLoadingIndicator
  },
  props: {
    channelId: { type: String as PropType<Guid>, required: true }
  },
  setup(props, context) {
    const store = useStore()
    const router = useRouter()
    const {showErrorNotification} = useNotifications(context)
    const {showDangerConfirmModal} = useModals(context)
    const {closePage} = useSharedUtils()

    const initialContentSourceId = router.currentRoute.query.contentSourceId

    const isLoading = ref(true)
    const isButtonsDisabled = ref(false)

    const isPageFullscreenMode = computed(() => store.state.ui.isPageFullscreenMode)

    const aiAgents = ref<AiAgent[] | null>(null)

    const contentSources = ref<ContentSource[] | null>(null)
    const selectedContentSourceId = ref<Guid | null>(null)

    const isCreateSourceItemPreviewAvailable = computed(() =>
      helpers.isNotEmpty(selectedContentSourceId.value) && helpers.isExists(model.value) &&
      (helpers.isNotEmpty(model.value.aiAgentLanguageId) ||
        helpers.isNotEmpty(model.value.aiAgentRoleId) ||
        helpers.isNotEmpty(model.value.aiAgentToneId)))

    const model = ref<ChannelAutomatedAssistantAiAgentInfo | null>(null)

    const isActivityItemsLoading = ref(false)
    const activityItems = ref<ChannelActivityAiAgentItem[] | null>(null)

    // need for tracking changes
    const modelInitial = ref<ChannelAutomatedAssistantAiAgentInfo | null>(null);
    const isModelChanged = computed(() => !helpers.isDeepEquals(modelInitial.value, model.value))

    const updateAiAgentInfo = (skipUpdateActivityItems: boolean, cb?: (success: boolean) => void) => {
      if (!helpers.isNotEmpty(props.channelId)) return

      isButtonsDisabled.value = true

      channelApi.getAutomatedAssistantAiAgentInfo(props.channelId, null, result => {
        if (helpers.isExists(result)) {
          model.value = result

          // update tracking changes object
          modelInitial.value = helpers.deepCopy(model.value);

          if (!skipUpdateActivityItems) {
            // update activity items
            updateAiAgentActivityItems((success) => {
              if (helpers.isFunction(cb))
                cb(true)

              isButtonsDisabled.value = false
            })
          } else {
            if (helpers.isFunction(cb))
              cb(true)

            isButtonsDisabled.value = false
          }
        } else {
          if (helpers.isFunction(cb))
            cb(false)

          isButtonsDisabled.value = false
        }
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
      })
    }

    const updateAiAgentActivityItems = (cb?: (success: boolean) => void) => {
      if (!helpers.isExists(model.value)) {
        if (helpers.isFunction(cb))
          cb(false)

        return
      }

      isActivityItemsLoading.value = true
      channelApi.getChannelAutomatedAssistantAiAgentActivityItems(model.value.channelId, result => {
        if (helpers.isExists(result)) {
          activityItems.value = result.items
        } else {
          activityItems.value = null
        }

        isActivityItemsLoading.value = false

        if (helpers.isFunction(cb))
          cb(true)
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isActivityItemsLoading.value = false

        if (helpers.isFunction(cb))
          cb(false)
      })
    }

    const saveAiAgentInfoChanges = (skipPreview: boolean) => {
      isButtonsDisabled.value = true

      channelApi.updateAutomatedAssistantAiAgent(props.channelId, model.value?.isEnabled ?? false,
        model.value?.aiAgentId ?? null, model.value?.aiAgentLanguageId ?? null,
        model.value?.aiAgentRoleId ?? null, model.value?.aiAgentToneId ?? null, r => {
          if (r.isAnyChanges) {
            updateAiAgentInfo(skipPreview, success => {
              if (success) {
                if (!skipPreview && isCreateSourceItemPreviewAvailable.value) {
                  createLastSourceItemPreview()
                }
              }
            })
          } else {
            isButtonsDisabled.value = false
          }
      }, errorMessage => {
          showErrorNotification(errorMessage)

          isButtonsDisabled.value = false
      })
    }

    const createLastSourceItemPreview = () => {
      if (!helpers.isExists(model.value) || !helpers.isNotEmpty(selectedContentSourceId.value)) return
      if (!isCreateSourceItemPreviewAvailable.value) return

      isButtonsDisabled.value = true
      isActivityItemsLoading.value = true

      channelApi.createChannelAutomatedAssistantAiAgentPreview(model.value.channelId, selectedContentSourceId.value, result => {
          if (result.isAnyChanges) {
            updateAiAgentActivityItems(success => {
              isButtonsDisabled.value = false
            })
          } else {
            isActivityItemsLoading.value = false
            isButtonsDisabled.value = false
          }
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
        isActivityItemsLoading.value = false
      })
    }

    const reloadAiAgentInfo = () => {
      isLoading.value = true

      updateAiAgentInfo(false, success => {
        if (success) {
          isLoading.value = false

          // lookup
          //
          contentSourceApi.contentSources(props.channelId, result => {
              contentSources.value = result.models

              if (helpers.isNotEmpty(initialContentSourceId)) {
                selectedContentSourceId.value = helpers.findFirstInArray(contentSources.value, x => x.id === initialContentSourceId)?.id ?? null
              }
            },
            errorMessage => showErrorNotification(errorMessage))
        } else {
          closePage()
        }
      })
    }

    const removeAllItemsWithConfirm = () => {
      if (!Helpers.isExists(model.value)) return

      showDangerConfirmModal(async (confirmed) => {
          if (confirmed) {
            isButtonsDisabled.value = true
            isActivityItemsLoading.value = true

            channelApi.removeAllChannelAutomatedAssistantAiAgentActivityItems(props.channelId, r => {
              if (r.success) {
                updateAiAgentActivityItems(success => {
                  isButtonsDisabled.value = false
                })
              } else {
                isActivityItemsLoading.value = false
                isButtonsDisabled.value = false
              }
            }, errorMessage => {
              showErrorNotification(errorMessage);

              isButtonsDisabled.value = false
              isActivityItemsLoading.value = false
            })
          }
        }, `Do you really want to clear all items in activity feed?`,
        "Clear Activity Feed")
    }

    watch(() => props.channelId, () => {
      reloadAiAgentInfo()
    })

    watch(() => [
      model.value?.aiAgentId,
      model.value?.aiAgentLanguageId,
      model.value?.aiAgentRoleId,
      model.value?.aiAgentToneId,
    ], () => {
      if (isModelChanged.value) {
        saveAiAgentInfoChanges(false)
      }
    })
    watch(() => [
      selectedContentSourceId.value,
    ], () => {
      if (!isCreateSourceItemPreviewAvailable.value) return

      createLastSourceItemPreview()
    })
    watch(() => [
      model.value?.isEnabled,
    ], () => {
      if (isModelChanged.value) {
        saveAiAgentInfoChanges(true)
      }
    })

    // init
    //
    onMounted(() => {
      reloadAiAgentInfo()
    })

    // lookup
    aiApi.aiAgents(AiAgentType.ChannelAutomatedAssistant, result => aiAgents.value = result!.models,
      errorMessage => showErrorNotification(errorMessage))

    return {
      helpers,
      appUtils,
      AiAgentType,

      isLoading,
      isButtonsDisabled,

      isPageFullscreenMode,

      aiAgents: aiAgents as unknown as AiAgent[],

      contentSources: contentSources as unknown as ContentSource[],
      selectedContentSourceId,
      isCreateSourceItemPreviewAvailable,

      model: model as unknown as ChannelAutomatedAssistantAiAgentInfo,
      modelInitial,
      isModelChanged,

      isActivityItemsLoading,
      activityItems: activityItems as unknown as ChannelActivityAiAgentItem[],

      saveAiAgentInfoChanges,
      createLastSourceItemPreview,
      removeAllItemsWithConfirm,
    }
  }
})
