import {EnumSelectOption, Guid} from "@/@models";

export enum AiAgentType {
  Undefined = 0,
  ChannelAutomatedAssistant = 1,
  ChannelLiveAssistant = 2,
}
export const AiAgentType_ALL: EnumSelectOption[] = [
  { label: 'Automated Assistant', id: AiAgentType.ChannelAutomatedAssistant },
  { label: 'Live Assistant', id: AiAgentType.ChannelLiveAssistant },
]

export interface AiAgentAdminTable {
  id: Guid

  type: AiAgentType
  name: string
}

export interface AiAgentLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
  inputInstruction: string | null
}

export interface AiAgentAdminEdit {
  // update only
  id: Guid | null

  type: AiAgentType | null

  localizations: AiAgentLocalizationAdminEdit[]

  promptTemplate: string | null
}

export interface AiLanguageAdminTable {
  id: Guid

  name: string
}

export interface AiLanguageLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiLanguageAdminEdit {
  // update only
  id: Guid | null

  localizations: AiLanguageLocalizationAdminEdit[]

  promptValue: string | null
}

export interface AiRoleAdminTable {
  id: Guid

  name: string
}

export interface AiRoleLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiRoleAdminEdit {
  // update only
  id: Guid | null

  localizations: AiRoleLocalizationAdminEdit[]

  promptValue: string | null
}

export interface AiToneAdminTable {
  id: Guid

  name: string
}

export interface AiToneLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiToneAdminEdit {
  // update only
  id: Guid | null

  localizations: AiToneLocalizationAdminEdit[]

  promptValue: string | null
}

export interface AiActionAdminTable {
  id: Guid

  name: string
}

export interface AiActionLocalizationAdminEdit {
  localizationId: Guid
  name: string | null
}

export interface AiActionAdminEdit {
  // update only
  id: Guid | null

  localizations: AiActionLocalizationAdminEdit[]

  promptTemplate: string | null
}