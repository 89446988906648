




















































import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import useStore from "@/store";
import {
  AiToneAdminTable
} from "@/@models";
import useConfig from "@/utils/useConfig";
import BSelectFormGroup from "@/views/_controls/BSelectFormGroup.vue";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";
import aiPortalApi from "@/api/portal/ai-portal-api";
import useNotifications from "@/components/useNotifications";

export default defineComponent({
  components: {
    BSelectFormGroup, BTextInputFormGroup
  },
  setup(props, context) {
    const config = useConfig()
    const { showErrorNotification } = useNotifications(context)

    const isButtonsDisabled = ref(false)

    const table = ref({
      isLoading: false,
      currentPage: 1,
      totalCount: -1,

      fields: [
        {
          key: 'actions',
          label: ''
        },
        {
          key: 'name',
          label: 'Name'
        },
      ]
    })

    const allItems = ref<AiToneAdminTable[] | null>(null)

    const updateAllItems = () => {
      table.value.isLoading = true
      isButtonsDisabled.value = true

      aiPortalApi.getAiTonesForTable(result => {
        allItems.value = result

        table.value.totalCount = allItems.value.length
        isButtonsDisabled.value = false;
        table.value.isLoading = false;
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false;
        table.value.isLoading = false;
      });
    }

    // init
    //
    updateAllItems()

    return {
      config,

      isButtonsDisabled,

      table,
      allItems,
    }
  }
})
