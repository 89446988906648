
import {
  Guid, AiAgents, AiLanguages, AiRoles, AiTones, AiActions, AiAgentType
} from '@/@models'
import ClientApiBase from "@/api/client-api-base";
import appUtils from "@/utils/app-utils";
import {ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends ClientApiBase {
  aiAgents(type: AiAgentType,
           successCb: ApiSuccessCallback<AiAgents>,
           errorCb: ApiErrorCallback<AiAgents>,
           onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai/ai-agents', {
      type, onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiLanguages(successCb: ApiSuccessCallback<AiLanguages>,
              errorCb: ApiErrorCallback<AiLanguages>,
              onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai/ai-languages', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiRoles(successCb: ApiSuccessCallback<AiRoles>,
          errorCb: ApiErrorCallback<AiRoles>,
          onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai/ai-roles', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiTones(successCb: ApiSuccessCallback<AiTones>,
          errorCb: ApiErrorCallback<AiTones>,
          onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai/ai-tones', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
  aiActions(successCb: ApiSuccessCallback<AiActions>,
          errorCb: ApiErrorCallback<AiActions>,
          onlyIds: Guid[] | null = null) {
    return this._defaultPostResponse('/ai/ai-actions', {
      onlyIds, telegramInitData: appUtils.getTelegramInitData()
    }, null, successCb, errorCb);
  }
}